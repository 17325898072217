<script>
  import { Route } from 'svelte-navigator';
  import PrivateRouteGuard from './PrivateRouteGuard.svelte';

  export let path;
  export let admin = false;
  export let keys = [];
</script>

<Route {path} let:params let:location let:navigate>
  <PrivateRouteGuard let:registerFocus {admin} {keys}>
    <slot {params} {location} {navigate} {registerFocus} />
  </PrivateRouteGuard>
</Route>
