export class User {
  constructor(clientId, accessToken, permissions, isShopBoxAdmin, clientFeatures, routeKeys = [], clientName, email, website) {
    this.clientId = clientId;
    this.accessToken = accessToken;
    this.permissions = permissions;
    this.isShopBoxAdmin = isShopBoxAdmin;
    this.clientFeatures = clientFeatures;
    this.routeKeys = routeKeys;
    this.clientName = clientName;
    this.email = email
    this.fullClientId = website ? website : clientId;
  }

  get hasAdminPermissions() {
    return this.permissions.admin || this.isShopBoxAdmin;
  }

  get hasApiPermissions() {
    return this.hasAdminPermissions || this.permissions.api_keys;
  }

  get hasConfigPermissions() {
    return this.hasAdminPermissions || this.permissions.config;
  }

  get hasOpenApiAccess() {
    return this.hasApiPermissions && this.clientFeatures.open_api;
  }
  get hasHomePermission() {
    return this.permissions.length > 0;
  }
  get hasPermissionToCurrentRoute() {
    return (this.routeKeys.length > 0 && this.routeKeys.some((role) => this.permissions[role]) == true) || this.isShopBoxAdmin
      ? true
      : false;
  }
}
