<script>
  import Select from 'svelte-select';
  import { createEventDispatcher } from 'svelte';
  import { clientAccess, currentClient, currentClientWebsite, currentUser } from '../stores';
  const dispatch = createEventDispatcher();
  const changeClient = (e) => {
    $currentClient = e.detail.id;
    $currentClientWebsite = e.detail.website;
    dispatch('changeClient');
  };
  const clientsWithWebsites = () => {
    let result = [];
    for (const key in $clientAccess) {
      let websitesFormatted = $clientAccess[key].websites.map((w) => {
        return {
          id: w.client_id,
          website: w.client_id + '-' + w.attr,
          name: $clientAccess[key].name + ' ' + w.name,
          feautures: $clientAccess[key].feautures,
          created_at: $clientAccess[key].created_at,
        };
      });
      $clientAccess[key].website = $clientAccess[key].id;
      result = [$clientAccess[key], ...websitesFormatted, ...result];
    }
    return result;
  };

  let initialValue = { id: $currentUser.clientId, name: $currentUser.clientName };
  let clients = [];
  $: if ($clientAccess) {
    clients = clientsWithWebsites();
  }
</script>

<div>
  <Select
    items={clients}
    value={initialValue}
    optionIdentifier="website"
    labelIdentifier="name"
    on:select={changeClient}
    placeholder={'Select Client'}
  />
</div>

<style>
  div {
    width: 100%;
  }
  @media (min-width: 640px) {
    div {
      max-width: 200px;
    }
  }
</style>
