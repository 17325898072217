<script>
  import { notifications } from '@shopboxai/sb-portal-components';
  import { useNavigate, useLocation, useFocus } from 'svelte-navigator';
  import { userToken } from '../stores';
  import { loginWithEmail, sendResetPasswordEmail } from '../api';
  import { onMount } from 'svelte';
  import Logo from '../Components/Logo.svelte';
  const registerFocus = useFocus();
  const navigate = useNavigate();
  const location = useLocation();

  let email = null;
  let password = null;
  let resetForm = false;

  const login = async () => {
    const res = await loginWithEmail(email, password);
    $userToken = { ...res };
    const from = '/';
    navigate(from, { replace: true });
  };

  async function resetPassword() {
    if (email) {
      try {
        const res = await sendResetPasswordEmail(email);
        notifications.success(res.detail, 5000);
        resetForm = false;
      } catch (error) {
        notifications.danger('Something went wrong..', 2000);
      }
    }
  }
  onMount(() => {
    $userToken = null;
  });
</script>

<div class="login-wrapper">
  <div class="form-container">
    {#if resetForm}
      <form on:submit|preventDefault={resetPassword}>
        <Logo />
        <h1 class="main-title">Send Recovery Email</h1>
        <p class="form-tip">Enter your email address below and you’ll receive an email with further instructions.</p>
        <label for="email">Email address</label>
        <input type="email" bind:value={email} required placeholder="Email" id="email" />
        <div class="btn-row">
          <button class="primary" type="submit"> Send Email </button>
          <button class="cancel" on:click={() => (resetForm = !resetForm)}>Cancel</button>
        </div>
      </form>
    {:else}
      <form on:submit|preventDefault={login}>
        <Logo />
        <h1 class="main-title">Log in to Shopbox portal</h1>
        <label for="email">Email address</label>
        <input type="email" bind:value={email} required placeholder="Email" id="email" />
        <label for="password">Password</label>
        <input type="password" bind:value={password} required placeholder="Password" id="password" />
        <div class="btn-row">
          <button use:registerFocus class="primary"> Login </button>
          <span style="  cursor: pointer;" on:click={() => (resetForm = !resetForm)}>Forgot password?</span>
        </div>
      </form>
    {/if}
  </div>

  <div class="get-in-touch" />
</div>

<style>
  :global(.sb-login) {
    padding: 0 !important;
  }
  .login-wrapper {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .get-in-touch {
    flex-basis: 50%;
    height: 100%;
    background: url('/img/login-image.png') no-repeat;
    background-size: 100%;
    background-position-x: right;
    background-position-y: center;
  }

  .btn-row {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .btn-row .primary {
    width: 100%;
    margin-bottom: 2em;
  }

  .form-container {
    flex-basis: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  form {
    max-width: 440px;
  }
  form h1 {
    margin-bottom: 1em;
  }
  form label {
    color: var(--t-c);
  }
  .form-tip {
    color: var(--t-c);
    font-size: 1.1rem;
    opacity: 0.9;
    margin-bottom: 3rem;
    display: inline-block;
  }
  input:not(input[type='checkbox']) {
    display: block;
    width: 100%;
    padding: 16px 20px;
    margin: 0px;
    border: 2px solid rgb(223, 223, 227);
    border-radius: 5px;
    outline: 0px;
    box-shadow: none;
    background-color: rgb(255, 255, 255);
    color: rgb(68, 74, 87);
    transition: border-color 0.2s ease 0s;
    position: relative;
    font-size: 15px;
    line-height: 1.5;
    margin-bottom: 1em;
  }

  @media (max-width: 768px) {
    .login-wrapper {
      flex-direction: column-reverse;
      justify-content: center;
    }
    .get-in-touch {
      display: none;
    }
  }
</style>
