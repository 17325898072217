import { writable } from 'svelte/store';

export const loaderShown = writable(false);
export const userToken = writable(JSON.parse(localStorage.getItem('sb-portal-user')) || null);
export const clientAccess = writable(false);
export const currentClient = writable(null);
export const currentClientWebsite = writable(null);
export const currentUser = writable(null);
export const sidebarConfig = writable([
  {
    admin: false,
    roles: [],
    route: '/',
    title: 'Home',
    icon: 'dashboard',
  },
  {
    admin: false,
    roles: ['dashboard'],
    route: 'dashboard',
    title: 'Reports',
    icon: 'reports',
  },
  {
    admin: true,
    roles: ['grid_builder'],
    route: 'grid-builder',
    title: 'Grid Builder',
    icon: 'carousel',
  },
  {
    admin: true,
    roles: ['config'],
    route: 'configs',
    title: 'Configs',
    icon: 'code',
  },
  {
    admin: true,
    roles: ['admin', 'api_keys'],
    route: 'admin',
    title: 'User Access',
    icon: 'settings',
  },
  {
    admin: true,
    roles: ['admin'],
    route: 'widget-customization',
    title: 'Widget Customization',
    icon: 'edit',
  },
  {
    admin: true,
    roles: ['shopbox_admin'],
    route: 'create-client',
    title: 'Create Client',
    icon: 'add',
  },
  {
    admin: true,
    roles: ['shopbox_admin'],
    route: 'go-live',
    title: 'Go Live',
    icon: 'golive',
  },
  {
    admin: false,
    roles: [],
    route: 'help',
    title: 'Help',
    icon: 'settings',
  },
]);

userToken.subscribe((value) => {
  localStorage.setItem('sb-portal-user', JSON.stringify(value));
});
