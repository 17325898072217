<script>
  import { onMount, tick } from 'svelte';
  import { Router, Route, Link } from 'svelte-navigator';
  import Logo from './Components/Logo.svelte';
  import Login from './routes/Login.svelte';
  import Home from './routes/Home.svelte';
  import PasswordReset from './routes/PasswordReset.svelte';
  import Admin from './routes/Admin.svelte';
  import GridBuilder from './routes/GridBuilder.svelte';
  import Dashboard from './routes/Dashboard.svelte';
  import Configs from './routes/Configs.svelte';
  import WidgetCustomization from './routes/WidgetCustomization.svelte';
  import CreateClient from './routes/CreateClient.svelte';
  import PrivateRoute from './routes/PrivateRoute.svelte';
  import { Icon, NotificationDisplay } from '@shopboxai/sb-portal-components';
  import { userToken, sidebarConfig, currentUser } from './stores';
  import GoLive from './routes/GoLive.svelte';
  import Help from './routes/Help.svelte';
  import ClientSwitcher from './Components/ClientSwitcher.svelte';

  let mounted = false;

  function handleLogout() {
    $userToken = null;
  }

  onMount(async () => {
    mounted = true;
  });

  let mobileSidebarActive = false;
  async function changeClient() {
    mounted = false;
    await tick();
    mounted = true;
  }
</script>

<svelte:head>
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
  <link href="https://fonts.googleapis.com/css2?family=Chivo:wght@900&family=Overpass:wght@400;500;700&display=swap" rel="stylesheet" />
</svelte:head>
{#if $currentUser && $userToken}
  <header id="main-header">
    <Logo />

    <button on:click={() => (mobileSidebarActive = !mobileSidebarActive)}>
      {#if mobileSidebarActive == true}
        <Icon name="close" />
      {:else}
        <Icon name="menu" />
      {/if}
    </button>
    <ClientSwitcher on:changeClient={changeClient} />
  </header>
{/if}

<NotificationDisplay />
{#if mounted}
  <div class="page-wrapper">
    <Router>
      {#if $currentUser && $userToken}
        <aside class:mobile-sidebar-visible={mobileSidebarActive}>
          {#each $sidebarConfig as link}
            {#if (link.admin == true && link.roles.some((role) => $currentUser.permissions[role]) == true) || link.admin == false || $currentUser.isShopBoxAdmin}
              <Link class="aside-link" to={link.route} on:click={() => (mobileSidebarActive = false)}
                ><Icon name={link.icon} />{link.title}</Link
              >
            {/if}
          {/each}

          <div class="spacer" />
          <a href=" " class="aside-link" on:click={handleLogout}> <Icon name="logout" />Log out</a>
        </aside>
      {/if}
      <main>
        <!-- public -->
        <Route path="login" let:registerFocus>
          <Login />
        </Route>

        <Route path="reset" let:registerFocus>
          <PasswordReset />
        </Route>

        <Route path="embed/:id" let:params>
          {params.id}
        </Route>

        <!-- private -->
        <PrivateRoute path="/" let:location let:registerFocus>
          <Home />
        </PrivateRoute>

        <PrivateRoute path="dashboard" keys={['dashboard']}>
          <Dashboard />
        </PrivateRoute>

        <!-- private with special permission  -->
        <PrivateRoute path="grid-builder" admin={true} keys={['grid_builder']}>
          <GridBuilder />
        </PrivateRoute>

        <PrivateRoute admin={true} path="admin" keys={['admin', 'api_keys']}>
          <Admin />
        </PrivateRoute>

        <!-- shopbox admin  -->
        <PrivateRoute admin={true} path="widget-customization" keys={['admin']}>
          <WidgetCustomization />
        </PrivateRoute>

        <PrivateRoute path="configs" admin={true} keys={['shopbox_admin']}>
          <Configs />
        </PrivateRoute>

        <PrivateRoute path="go-live" admin={true} keys={['shopbox_admin']}>
          <GoLive />
        </PrivateRoute>

        <PrivateRoute path="create-client" admin={true} keys={['shopbox_admin']}>
          <CreateClient />
        </PrivateRoute>
        <PrivateRoute path="help">
          <Help />
        </PrivateRoute>
      </main>
    </Router>
  </div>
{/if}

<style>
  @media (min-width: 640px) {
    .page-wrapper {
      display: flex;
      min-height: 100vh;
    }
  }

  main {
    padding: 1em;
    flex: 1;
    border-left: 1px solid #efefef;
  }
</style>
