<script>
  import { LogoLoader } from '@shopboxai/sb-portal-components';
  import { onMount } from 'svelte';
  import { useNavigate, useLocation, useFocus } from 'svelte-navigator';
  import { userToken, currentUser, loaderShown } from '../stores';
  import { asyncCheckAuthStatus } from '../api';

  export let admin = false;
  export let keys = [];

  let isChecking = true;
  let roleCheck = false;

  const navigate = useNavigate();
  const location = useLocation();
  const registerFocus = useFocus();

  const navigateToLogin = () => {
    navigate('/login', {
      state: { from: $location.pathname },
      replace: true,
    });
  };
  const navigateToHome = () => {
    navigate('/', {
      state: { from: $location.pathname },
      replace: true,
    });
  };

  onMount(async () => {
    try {
      await asyncCheckAuthStatus(keys);
      if (admin) {
        roleCheck = $currentUser.hasPermissionToCurrentRoute;
      } else {
        roleCheck = true;
      }
    } catch {
      navigateToLogin();
    } finally {
      if (!$currentUser.hasPermissionToCurrentRoute) {
        navigateToHome();
      }
      isChecking = false;
      $loaderShown = true;
    }
  });
  $: if (!$userToken && !isChecking) {
    navigateToLogin();
  }
</script>

{#if $userToken && !isChecking && roleCheck}
  <slot {registerFocus} />
{/if}

{#if isChecking}
  {#if $loaderShown == false}
    <div><LogoLoader /></div>
  {/if}
{/if}

<style>
  div {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    transform: scale(2);
    transform-origin: center;
  }
</style>
