import { get } from 'svelte/store';
import { User } from './user';
import { userToken, clientAccess, currentClient, currentUser, currentClientWebsite } from './stores';

export const url = () => {
  return process['env'].PROD_ENV == true || process['env'].PROD_ENV == 'true'
    ? 'https://auth.shopbox.ai'
    : 'https://beta-sb-auth-vlwut6qbsq-lz.a.run.app';
};
export const loginWithEmail = (email, password) => {
  return fetch(url() + '/api/v1/login/token', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: `username=${email}&password=${encodeURIComponent(password)}`,
  }).then((res) => res.json());
};
export const registerUser = (email, password) => {
  fetch(url() + '/api/v1/users/', {
    body: `{ "password": "${password}", "email": "${email}"}`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'POST',
  });
};
export const sendResetPasswordEmail = (email) => {
  return fetch(url() + '/api/v1/password-recovery/' + email, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((res) => res.json());
};
export const saveNewPassword = (password, token) => {
  return fetch(url() + '/api/v1/reset-password/', {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(password),
  }).then((res) => res.json());
};

export const getToken = () => {
  return get(userToken).access_token ? get(userToken).access_token : null;
};

export const makeAdministator = async () => {
  const params = '?admin=true&dashboard=true&grid_builder=true&billing=true&api_keys=true&config=true';
  const res = await fetch(url() + `/api/v1/users/permissions${params}`, {
    method: 'PATCH',
    headers: { Authorization: `Bearer ${getToken()}` },
  });
};

export const getClientAccess = async () => {
  const userInfo = await fetch(url() + '/api/v1/users/me?clients=true', {
    headers: { Authorization: `Bearer ${getToken()}` },
  });
  if (userInfo.ok) {
    const userInfoJSON = await userInfo.json();
    if (userInfoJSON.clients) {
      clientAccess.set(userInfoJSON.clients);
      currentClient.set(userInfoJSON.cid);
    }
    return get(currentClient);
  } else {
    clientAccess.set(false);
    throw new Error('User has no client access');
  }
};

export const asyncCheckAuthStatus = async (routeKeys) => {
  const ca = get(clientAccess);

  if (!ca) await getClientAccess();

  const userInfo = await fetch(url() + `/api/v1/users/me?permissions=true&clients=true&cid=${get(currentClient)}`, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });

  if (userInfo.ok) {
    const userInfoJSON = await userInfo.json();
    const newUser = new User(
      get(currentClient),
      getToken(),
      userInfoJSON.permissions,
      userInfoJSON.shopbox_admin,
      userInfoJSON.client.features,
      routeKeys,
      userInfoJSON.client.name,
      userInfoJSON.email,
      get(currentClientWebsite)
    );
    currentUser.set(newUser);
    if (userInfoJSON.clients) {
      clientAccess.set(userInfoJSON.clients);
    }
    return get(currentUser);
  } else {
    userToken.set(null);
    throw new Error('User not authenticated');
  }
};
