<div class="logo">
  <svg width="129" height="40" viewBox="0 0 129 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.4509 40C16.9627 40.0013 16.4828 39.8723 16.0593 39.6261L1.39162 31.047C0.96995 30.8001 0.619524 30.4454 0.37533 30.0183C0.131136 29.5912 0.00171826 29.1066 0 28.6129L0 11.4124C0.000500623 10.9175 0.129341 10.4315 0.373596 10.003C0.617851 9.57454 0.968931 9.21874 1.39162 8.9713L16.0662 0.378086C16.4893 0.130397 16.9693 0 17.4578 0C17.9464 0 18.4264 0.130397 18.8495 0.378086L33.531 8.9713C33.9537 9.21874 34.3048 9.57454 34.5491 10.003C34.7933 10.4315 34.9221 10.9175 34.9226 11.4124V28.5988C34.9209 29.0925 34.7915 29.5771 34.5473 30.0042C34.3031 30.4313 33.9527 30.786 33.531 31.0329L18.8425 39.6261C18.419 39.8723 17.9391 40.0013 17.4509 40ZM17.4509 1.47164C17.219 1.46999 16.9909 1.53086 16.7899 1.64802L2.08743 10.2412C1.88591 10.3608 1.71884 10.5319 1.60286 10.7374C1.48688 10.9429 1.42604 11.1756 1.42641 11.4124V28.5988C1.42585 28.8347 1.48667 29.0665 1.60273 29.2709C1.7188 29.4753 1.88599 29.645 2.08743 29.7629L16.769 38.3561C16.9713 38.4737 17.2003 38.5356 17.4335 38.5356C17.6666 38.5356 17.8957 38.4737 18.098 38.3561L32.7796 29.7629C32.9865 29.6484 33.1599 29.4804 33.2821 29.2759C33.4044 29.0715 33.471 28.8379 33.4754 28.5988V11.4124C33.4708 11.1725 33.4041 10.938 33.282 10.7324C33.1599 10.5268 32.9866 10.3575 32.7796 10.2412L18.091 1.64802C17.8954 1.53615 17.6754 1.47553 17.4509 1.47164Z"
      fill="#40ADFF"
    />
    <path
      d="M46.6906 17.5289C46.3994 16.2058 44.9787 15.6408 43.7072 15.6623C43.2111 15.6481 42.7177 15.7402 42.2597 15.9326C41.8017 16.1249 41.3895 16.4131 41.0505 16.778C40.7644 17.1715 40.6375 17.6598 40.6953 18.144C40.8019 19.6602 42.4499 19.8461 44.0197 19.9963C46.0229 20.2466 48.5091 20.7687 49.0702 23.1288C49.1361 23.4465 49.1718 23.7698 49.1768 24.0943C49.1768 26.9551 46.3781 28.5714 43.7498 28.5714C41.4909 28.5714 38.7774 27.2269 38.4862 24.6021V24.2016L40.6172 24.1587V24.4734V24.3876C40.7664 25.7536 42.4144 26.4688 43.7782 26.4688C45.341 26.4688 47.0529 25.5891 47.0529 24.0729C47.05 23.9018 47.0286 23.7315 46.9889 23.5651C46.7403 22.4566 45.2344 22.2635 43.7782 22.1347C41.498 21.8987 38.8058 21.4195 38.5927 18.3228C38.4984 17.323 38.7825 16.3242 39.3883 15.5264C39.9362 14.8912 40.6175 14.3865 41.3829 14.0489C42.1482 13.7112 42.9785 13.549 43.8137 13.5739C46.0726 13.5739 48.4096 14.7468 48.7861 17.4431L46.6906 17.5289Z"
      fill="#100B3F"
    />
    <path
      d="M52.784 19.9041H59.1111V13.5714H61.2928V28.5714H59.1111V22.049H52.784V28.5714H50.6022V13.5714H52.784V19.9041Z"
      fill="#100B3F"
    />
    <path
      d="M64.9032 15.7811C65.5951 15.0813 66.4199 14.5255 67.3295 14.1461C68.2392 13.7668 69.2155 13.5714 70.2017 13.5714C71.1878 13.5714 72.1642 13.7668 73.0738 14.1461C73.9834 14.5255 74.8082 15.0813 75.5001 15.7811C76.8998 17.1878 77.6851 19.088 77.6851 21.0684C77.6851 23.0487 76.8998 24.949 75.5001 26.3557C74.8092 27.0572 73.9847 27.6145 73.075 27.995C72.1652 28.3755 71.1884 28.5714 70.2017 28.5714C69.2149 28.5714 68.2381 28.3755 67.3283 27.995C66.4186 27.6145 65.5941 27.0572 64.9032 26.3557C63.5035 24.949 62.7182 23.0487 62.7182 21.0684C62.7182 19.088 63.5035 17.1878 64.9032 15.7811ZM73.9944 17.2877C72.9855 16.2814 71.6188 15.7119 70.1909 15.7029C69.4909 15.705 68.7983 15.8462 68.1537 16.1183C67.5092 16.3905 66.9257 16.788 66.4374 17.2877C65.9375 17.7841 65.5409 18.3739 65.2702 19.0233C64.9996 19.6727 64.8603 20.3689 64.8603 21.0719C64.8603 21.775 64.9996 22.4712 65.2702 23.1206C65.5409 23.7699 65.9375 24.3597 66.4374 24.8562C66.9257 25.3558 67.5092 25.7534 68.1537 26.0255C68.7983 26.2976 69.4909 26.4389 70.1909 26.441C71.2517 26.4319 72.2863 26.112 73.1657 25.5211C74.0451 24.9303 74.7304 24.0947 75.136 23.1185C75.5416 22.1424 75.6497 21.0689 75.4467 20.0319C75.2436 18.995 74.7386 18.0406 73.9944 17.2877Z"
      fill="#100B3F"
    />
    <path
      d="M84.3979 13.5714C87.5155 13.5714 89.0884 16.0775 89.0884 18.5836C89.0884 21.0896 87.5155 23.5811 84.3979 23.5811H81.217V28.5714H79.1105V13.5714H84.3979ZM84.3979 21.432C84.7535 21.4408 85.1069 21.3728 85.4359 21.2325C85.7649 21.0922 86.0622 20.8826 86.309 20.6169C86.5559 20.3512 86.7469 20.0352 86.87 19.689C86.993 19.3427 87.0455 18.9738 87.024 18.6054C87.0474 18.2355 86.9965 17.8647 86.8744 17.5163C86.7523 17.168 86.5618 16.8497 86.3148 16.5819C86.0679 16.314 85.7699 16.1024 85.4399 15.9604C85.1099 15.8183 84.7551 15.7491 84.3979 15.7569H81.217V21.432H84.3979Z"
      fill="#100B3F"
    />
    <path
      d="M96.5179 13.5735C97.2752 13.5474 98.0209 13.7718 98.6486 14.2146C99.2763 14.6574 99.7537 15.296 100.013 16.0391C100.347 16.7667 100.425 17.5937 100.233 18.3753C100.041 19.1569 99.5921 19.8431 98.9643 20.3138C99.6547 20.626 100.238 21.1494 100.637 21.815C101.037 22.4805 101.233 23.2568 101.201 24.0414C101.201 26.2955 99.8031 28.5714 97.0072 28.5714H90.5138V13.5735H96.5179ZM92.5897 19.5405H96.476C98.7336 19.5405 98.7756 15.6306 96.5179 15.6306H92.5897V19.5405ZM92.5897 26.4705H97.0421C97.3342 26.4655 97.6221 26.3981 97.8882 26.2725C98.1543 26.1469 98.393 25.9657 98.5894 25.7402C98.7859 25.5146 98.936 25.2495 99.0305 24.9611C99.1251 24.6727 99.162 24.3671 99.139 24.0633C99.1643 23.7603 99.1288 23.4553 99.0348 23.1674C98.9408 22.8796 98.7903 22.6154 98.593 22.3917C98.3957 22.1679 98.1559 21.9896 97.8889 21.8679C97.6219 21.7463 97.3335 21.684 97.0421 21.6852H92.6037L92.5897 26.4705Z"
      fill="#100B3F"
    />
    <path
      d="M104.102 15.7811C104.794 15.0813 105.619 14.5255 106.528 14.1461C107.438 13.7668 108.414 13.5714 109.401 13.5714C110.387 13.5714 111.363 13.7668 112.273 14.1461C113.182 14.5255 114.007 15.0813 114.699 15.7811C116.099 17.1878 116.884 19.088 116.884 21.0684C116.884 23.0487 116.099 24.949 114.699 26.3557C114.008 27.0572 113.184 27.6145 112.274 27.995C111.364 28.3755 110.387 28.5714 109.401 28.5714C108.414 28.5714 107.437 28.3755 106.527 27.995C105.617 27.6145 104.793 27.0572 104.102 26.3557C102.702 24.949 101.917 23.0487 101.917 21.0684C101.917 19.088 102.702 17.1878 104.102 15.7811ZM113.186 17.2877C112.181 16.2805 110.816 15.7107 109.39 15.7029C108.69 15.705 107.997 15.8462 107.353 16.1183C106.708 16.3905 106.125 16.788 105.636 17.2877C105.136 17.7841 104.74 18.3739 104.469 19.0233C104.199 19.6727 104.059 20.3689 104.059 21.0719C104.059 21.775 104.199 22.4712 104.469 23.1206C104.74 23.7699 105.136 24.3597 105.636 24.8562C106.125 25.3558 106.708 25.7534 107.353 26.0255C107.997 26.2976 108.69 26.4389 109.39 26.441C110.45 26.4305 111.484 26.1096 112.362 25.5182C113.24 24.9269 113.924 24.0912 114.329 23.1153C114.734 22.1394 114.841 21.0665 114.638 20.0302C114.435 18.9939 113.93 18.0402 113.186 17.2877Z"
      fill="#100B3F"
    />
    <path
      d="M118.126 13.5714L122.222 19.1882L126.355 13.5714H129L123.548 21.0896C125.168 23.2752 127.556 26.5534 129 28.5496H126.355L122.222 22.9546L118.126 28.5714H115.459L120.903 21.1115C119.291 18.926 116.836 15.502 115.459 13.5933L118.126 13.5714Z"
      fill="#100B3F"
    />
    <path
      d="M17.2873 20.2981L5.16913 27.0037C5.00684 27.0943 4.8225 27.1423 4.63469 27.1429C4.44687 27.1434 4.26223 27.0964 4.09939 27.0068C3.93655 26.9171 3.80127 26.7878 3.70719 26.632C3.61311 26.4762 3.56356 26.2994 3.56354 26.1194V12.7082C3.56302 12.5274 3.61249 12.3496 3.70693 12.1931C3.80136 12.0365 3.93739 11.9067 4.10116 11.8169L16.2121 5.13917C16.3749 5.04825 16.5599 5.00023 16.7483 5C16.9368 4.99977 17.1219 5.04732 17.285 5.13784C17.448 5.22836 17.5832 5.35861 17.6767 5.51538C17.7703 5.67216 17.8189 5.84986 17.8177 6.03046V19.4138C17.8176 19.5928 17.7687 19.7686 17.6756 19.9238C17.5825 20.0789 17.4487 20.208 17.2873 20.2981Z"
      fill="#091EB5"
    />
    <path
      d="M10.6906 29.6901V16.2945C10.6899 16.1156 10.7385 15.9397 10.8316 15.7845C10.9247 15.6294 11.0589 15.5006 11.2207 15.4112L23.3255 8.71338C23.4884 8.62164 23.6737 8.57268 23.8627 8.57144C24.0517 8.57019 24.2377 8.61671 24.4019 8.7063C24.5661 8.79588 24.7028 8.92537 24.7981 9.08169C24.8934 9.23802 24.944 9.41565 24.9448 9.59668V22.9923C24.9445 23.1728 24.8948 23.35 24.8005 23.5063C24.7061 23.6625 24.5706 23.7923 24.4074 23.8825L12.2954 30.5803C12.1323 30.6689 11.9479 30.7151 11.7604 30.7143C11.5729 30.7134 11.3889 30.6657 11.2267 30.5757C11.0644 30.4857 10.9296 30.3566 10.8356 30.2012C10.7415 30.0459 10.6916 29.8696 10.6906 29.6901Z"
      fill="#0D4DCC"
    />
    <path
      d="M17.8177 33.9778V20.5835C17.8172 20.4029 17.8666 20.2254 17.961 20.069C18.0554 19.9126 18.1913 19.783 18.355 19.6933L30.467 12.9962C30.6297 12.9054 30.8146 12.8574 31.003 12.8572C31.1913 12.8569 31.3764 12.9044 31.5393 12.9948C31.7023 13.0852 31.8374 13.2153 31.9309 13.3719C32.0244 13.5285 32.073 13.706 32.0718 13.8863V27.2807C32.0739 27.4582 32.0275 27.6332 31.937 27.7882C31.8466 27.9431 31.7153 28.0727 31.5562 28.1639L19.4224 34.8611C19.2602 34.9516 19.076 34.9995 18.8883 35C18.7006 35.0006 18.516 34.9537 18.3533 34.8641C18.1905 34.7745 18.0553 34.6454 17.9613 34.4898C17.8672 34.3342 17.8177 34.1576 17.8177 33.9778Z"
      fill="#40ADFF"
    />
  </svg>
</div>

<style>
  .logo {
    margin-bottom: 2em;
  }
</style>
